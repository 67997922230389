import React, { useEffect, useState } from 'react';

import { globalHistory } from '@reach/router';
import useCookies from '../../hooks/useCookies';
import useSiteLocation from '../../hooks/useSiteLocation';
import Anchor from '../ui/Anchor';
import Button from '../ui/button';
import { Container } from './styles';

const CookieConsentMessage = () => {
  const { rootPath } = useSiteLocation();
  const { getCookie, setCookie } = useCookies();
  const [isShown, setIsShown] = useState(false);

  const handleAccept = () => {
    setCookie('acceptCookies', true, { expires: 365 });
    setIsShown(false);
  };

  useEffect(() => {
    if (!getCookie('acceptCookies')) setIsShown(true);

    return globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH' && !['/politica-de-cookies'].includes(location.pathname)) handleAccept();
    });
  }, [getCookie]);

  return isShown ? (
    <Container>
      <div>
        <b>Nosso site utiliza cookies para melhorar a sua experiência de navegação!</b>
        <br />
        Ao fechar este banner ou continuar navegando, você concorda com o uso de cookies.{' '}
        <Anchor
          path={`${rootPath}/politica-de-privacidade`}
          target="_blank"
          fontWeight="800"
          color="primaryLight"
          hover={{ color: 'secondary' }}
          allowBlank
        >
          Saiba mais
        </Anchor>
      </div>
      <Button onClick={handleAccept}>Aceitar</Button>
    </Container>
  ) : null;
};

export default CookieConsentMessage;
